import { isDev } from "../../components/pages/costants";
import { methodGet, verticalFetch } from "./httpRequests";
import { getLastDataUrl } from "./url";

export const getLastData = (id: string, tag: string, variable: string) => {
  if (tag === "z" && isDev) {
    return new Promise((resolve, reject) => {
      resolve({
        result: [
          {
            timestamp_device: "2025-02-07T11:01:22Z",
            timestamp_in: "2025-02-07T11:01:28.692Z",
            fleet_id: "flt-example",
            device_id: "dev-example",
            tag: "z",
            payload: {
              ts: 1739789850,
              macchina: [false, "avg", 900, [17000, 22000, 28000, 32000]],
              macina_1: [true, "avg", 1500, [1000, 2500, 5000]],
              macina_2: [false, "avg", 1500, [1000, 3600, 6000]],
              ev: ["NC", "NO", "NO"],
              bar: 15,
            },
          },
        ],
      });
    });
  }
  if (tag === "d" && isDev) {
    return new Promise((resolve, reject) => {
      resolve({
        result: {
          first_ts: "2024-12-12T13:07:00Z",
          last_ts: "2024-12-12T13:07:00Z",
          values: [
            {
              value: {
                A: [0, 0, 0, 0, 0],
                DT_store: 60,
                c_finali: {
                  doppio: [0, 0, 0],
                  singolo: [0, 0, 0],
                },
                ev1: {
                  A: [0, 0],
                  S: 0,
                  T: [60, 0],
                  fall_cnt: 0,
                  n_caffe: [0, 0, 0, 0],
                  n_samples: 120,
                  raise_cnt: 0,
                },
                ev2: {
                  A: [0, 0],
                  S: 0,
                  T: [60, 0],
                  fall_cnt: 0,
                  n_caffe: [0, 0, 0, 0],
                  n_samples: 120,
                  raise_cnt: 0,
                },
                ev3: {
                  A: [0, 0],
                  S: 0,
                  T: [60, 0],
                  fall_cnt: 0,
                  n_caffe: [0, 0, 0, 0],
                  n_samples: 120,
                  raise_cnt: 0,
                },
                mac_op: {
                  doppio: [0, 0],
                  singolo: [0, 0],
                },
                macchina: {
                  S: 0,
                  T: [60, 0, 0],
                  e: [0],
                  e_status: [0, 0, 0],
                  i_eff: [0, -3.5],
                  p_stats: [0, 0, 0, 0],
                  p_status: [0, 0, 0],
                },
                macina_1: {
                  S: 1,
                  T: [0, 60],
                  costo: 21,
                  e: [275.774],
                  e_status: [0, 275.774],
                  "g/dose": 4.5,
                  i_eff: [71.8934, 68.3934],
                  n_caffe: {
                    doppio: [0, 0],
                    singolo: [0, 0],
                  },
                  p_stats: [16546.4, 16535.5, 16570, 1985569],
                  p_status: [0, 16546.4],
                },
                macina_2: {
                  S: 1,
                  T: [0, 60],
                  costo: 0,
                  e: [276.056],
                  e_status: [0, 276.056],
                  "g/dose": 0,
                  i_eff: [71.8934, 68.3934],
                  n_caffe: {
                    doppio: [0, 0],
                    singolo: [0, 0],
                  },
                  p_stats: [16563.4, 16535.5, 16570, 1987605],
                  p_status: [0, 16563.4],
                },
                operatore: [0, 0],
                ts: 1734008820,
              },
              ts: "2024-12-12T13:07:00Z",
            },
          ],
        },
      });
    });
  }
  return verticalFetch(methodGet, getLastDataUrl(id, tag, variable));
};
