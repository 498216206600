import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
} from "@mui/material";
import CustomInput from "../../custom/CustomInput";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import CustomButton from "../../custom/CustomButton";
import { useSnackbar } from "notistack";
import CustomDivider from "../../custom/CustomDivider";
import CustomSelect from "../../custom/CustomSelect";
import {
  prepareConfigJson,
  prepareNetTestJson,
  prepareParamsJson,
  prepareSensorsJson,
} from "../../custom/utils";
import {
  init,
  commit,
  uploadFiles,
} from "../../../api/services/configuratorService";

type DialogThresholdProps = {
  id: string;
  net: any;
  params: any;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  doubleGrinder: boolean;
  runningZfs: boolean;
};

const DialogThreshold: React.FC<DialogThresholdProps> = ({
  id,
  net,
  params,
  open,
  onCancel,
  onConfirm,
  doubleGrinder,
  runningZfs,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = useState({
    machine_triphase: params?.macchina_tri_phase || "",
    machine_aggregation: params?.macchina_mode || "",
    machine_value: params?.macchina_w_threshold || "",
    grinder1_triphase: params?.macina1_tri_phase || "",
    grinder1_aggregation: params?.macina1_mode || "",
    grinder1_value: params?.macina1_w_threshold || "",
    grinder2_triphase: params?.macina2_tri_phase || "",
    grinder2_aggregation: params?.macina2_mode || "",
    grinder2_value: params?.macina2_w_threshold || "",
    ev1: params?.ev1 || "",
    ev2: params?.ev2 || "",
    ev3: params?.ev3 || "",
  });

  const handleSubmit = () => {
    const netFile = {
      file: new File(
        [new TextEncoder().encode(JSON.stringify(net))] as BlobPart[],
        "net.json"
      ),
    };
    const paramsFile = {
      file: new File(
        [
          new TextEncoder().encode(
            JSON.stringify(
              prepareParamsJson(
                Number(params.costo1),
                Number(params.costo2),
                Number(params.dose1),
                Number(params.dose2),
                params.thresholds,
                params.macinatore1,
                params.macinatore2,
                params.attraversamento,
                Number(values.machine_value),
                Number(values.grinder1_value),
                Number(values.grinder2_value),
                values.machine_triphase,
                values.grinder1_triphase,
                values.grinder2_triphase,
                values.ev1 as "NO" | "NC",
                values.ev2 as "NO" | "NC",
                values.ev3 as "NO" | "NC"
              )
            )
          ),
        ] as BlobPart[],
        "params.json"
      ),
    };
    const netTestFile = {
      file: new File(
        [
          new TextEncoder().encode(JSON.stringify(prepareNetTestJson)),
        ] as BlobPart[],
        "net_test.json"
      ),
    };
    const sensorsFile = {
      file: new File(
        [
          new TextEncoder().encode(JSON.stringify(prepareSensorsJson)),
        ] as BlobPart[],
        "sensors.json"
      ),
    };
    const configFile = {
      file: new File(
        [
          new TextEncoder().encode(JSON.stringify(prepareConfigJson)),
        ] as BlobPart[],
        "config.json"
      ),
    };
    init(id)
      .then(
        async () =>
          await uploadFiles(id, [
            netFile,
            paramsFile,
            sensorsFile,
            configFile,
            netTestFile,
          ]).then(async (res: any) => {
            if (res && res.files) {
              await commit(id, true).then((r: any) => {
                if (r && r.version) {
                  enqueueSnackbar(t("parametersSuccess"), {
                    variant: "success",
                  });
                  onConfirm();
                } else {
                  enqueueSnackbar(
                    t("parametersError") + " " + res?.err?.message,
                    { variant: "error" }
                  );
                }
              });
            } else {
              enqueueSnackbar(t("parametersError") + " " + res?.err?.message, {
                variant: "error",
              });
            }
          })
      )
      .catch((err: any) => {
        console.log("ERROR", err);
        enqueueSnackbar(t("parametersError"), {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    console.log("DIALOG PARAMS ", params);
  }, [params]);

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="md" fullWidth>
      <DialogTitle>{t("workingParameters")}</DialogTitle>
      <DialogContent style={{ paddingTop: "16px" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <CustomDivider label={`${t("machine")}`} textAlign="center" />
          </Grid>
          <Grid item xs={12} md={3}>
            <Switch
              checked={values.machine_triphase}
              onChange={() =>
                setValues({
                  ...values,
                  machine_triphase: !values.machine_triphase,
                })
              }
            />
            {t("triphase")}
          </Grid>
          <Grid item xs={12} md={9}>
            <CustomInput
              type="number"
              label={`${t("value")}`}
              value={values.machine_value}
              handleChange={(e: any) =>
                setValues({ ...values, machine_value: e.target.value })
              }
              endAdornment="W"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomDivider label={`${t("grinder")} 1`} textAlign="center" />
          </Grid>
          <Grid item xs={12} md={3}>
            <Switch
              checked={values.grinder1_triphase}
              onChange={() =>
                setValues({
                  ...values,
                  grinder1_triphase: !values.grinder1_triphase,
                })
              }
            />
            {t("triphase")}
          </Grid>
          <Grid item xs={12} md={9}>
            <CustomInput
              type="number"
              label={`${t("value")}`}
              value={values.grinder1_value}
              handleChange={(e: any) =>
                setValues({ ...values, grinder1_value: e.target.value })
              }
              endAdornment="W"
            />
          </Grid>
          {doubleGrinder && (
            <Grid item xs={12}>
              <CustomDivider label={`${t("grinder")} 2`} textAlign="center" />
            </Grid>
          )}
          {doubleGrinder && (
            <Grid item xs={12} md={3}>
              <Switch
                checked={values.grinder2_triphase}
                onChange={() =>
                  setValues({
                    ...values,
                    grinder2_triphase: !values.grinder2_triphase,
                  })
                }
              />
              {t("triphase")}
            </Grid>
          )}
          {doubleGrinder && (
            <Grid item xs={12} md={9}>
              <CustomInput
                type="number"
                label={`${t("value")}`}
                value={values.grinder2_value}
                handleChange={(e: any) =>
                  setValues({ ...values, grinder2_value: e.target.value })
                }
                endAdornment="W"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <CustomDivider label={`${t("valves")}`} textAlign="center" />
          </Grid>
          <Grid item xs={12} md>
            <CustomSelect
              label={`${t("valve")} 1`}
              options={[
                { label: `${t("NO")}`, value: "NO" },
                { label: `${t("NC")}`, value: "NC" },
              ]}
              value={values.ev1}
              handleChange={(e: any) =>
                setValues({ ...values, ev1: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md>
            <CustomSelect
              label={`${t("valve")} 2`}
              options={[
                { label: `${t("NO")}`, value: "NO" },
                { label: `${t("NC")}`, value: "NC" },
              ]}
              value={values.ev2}
              handleChange={(e: any) =>
                setValues({ ...values, ev2: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md>
            <CustomSelect
              label={`${t("valve")} 3`}
              options={[
                { label: `${t("NO")}`, value: "NO" },
                { label: `${t("NC")}`, value: "NC" },
              ]}
              value={values.ev3}
              handleChange={(e: any) =>
                setValues({ ...values, ev3: e.target.value })
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CustomButton type="outlined" label={t("reset")} onClick={onCancel} />
        <CustomButton
          type="contained"
          label={t("ok")}
          onClick={handleSubmit}
          disabled={runningZfs}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DialogThreshold;
