import { isDev } from "../../components/pages/costants";
import { CreateNewDeviceRequest } from "../requests/configuratorService";
import {
  ZDMFetch,
  apiKey,
  binaryFetch,
  firmwareId,
  methodGet,
  methodPost,
  methodPut,
  verticalFetch,
  workspaceId,
} from "./httpRequests";
import {
  activeSimUrl,
  checkZfsStatusUrl,
  claimDeviceUrl,
  commitUrl,
  createNewDeviceUrl,
  getCloudDeviceUrl,
  getFirmwareVersionsUrl,
  getIdentityFromDCNUrl,
  getZfsFileUrl,
  getZfsUrl,
  initUrl,
  mklfsUrl,
  sendAlarmToRoastingUrl,
  uploadFilesUrl,
} from "./url";

export const createNewDevice = (req: CreateNewDeviceRequest) => {
  if (isDev) {
    return new Promise((resolve, reject) => {
      resolve({
        device: {
          id: "dev-example",
          name: "example",
          account_id: "example",
          fleet_id: "flt-example",
          fleet_name: "flt-example",
          workspace_id: "wks-example",
          workspace_name: "example",
          created_at: "2024-10-22T09:59:50.729035Z",
          identities: [],
          is_connected: false,
        },
      });
    });
  }
  return ZDMFetch(
    methodPost,
    createNewDeviceUrl(workspaceId),
    JSON.stringify(req)
  );
};

export const getIdentityFromDCN = (dcn: string) => {
  if (isDev) {
    return new Promise((resolve, reject) => {
      resolve({
        identity: {
          device_id: "test_sf",
        },
        error: null,
      });
    });
  }
  return ZDMFetch(methodGet, getIdentityFromDCNUrl(dcn));
};

export const claimDevice = (deviceId: string, bundle: string) => {
  if (isDev) {
    return new Promise((resolve, reject) => {
      resolve({
        identity: {
          device_id: "test_sf",
        },
        error: null,
      });
    });
  }
  return ZDMFetch(
    methodPost,
    claimDeviceUrl(workspaceId, deviceId),
    JSON.stringify({ bundle: bundle })
  );
};

export const ActiveSim = (deviceId: string) => {
  if (isDev) {
    return new Promise((resolve, reject) => {
      resolve({
        sim: {
          id: "sim-example",
          iccid: "123456789012",
          status: "active",
          created_at: "2024-10-22T09:59:50.729035Z",
        },
      });
    });
  }
  return ZDMFetch(
    methodPut,
    activeSimUrl(workspaceId, deviceId),
    JSON.stringify({ status: "active" })
  );
};

export const mklfs = (files: { files: { [path: string]: string } }) => {
  return binaryFetch(methodPost, mklfsUrl, JSON.stringify(files));
};

export const init = (deviceId: string) => {
  if (isDev) {
    return new Promise((resolve, reject) => {
      resolve({ result: "ZFS staging initialization completed successfully" });
    });
  }
  return ZDMFetch(methodPost, initUrl(workspaceId, deviceId));
};

export const uploadFiles = (deviceId: string, files: { file: File }[]) => {
  if (isDev) {
    return new Promise((resolve, reject) => {
      resolve({
        version: "zfv-example",
        num: 1,
        size: 46007,
        files: [
          {
            id: "zfs-example",
            crc: "c9fd157f6fb037cb67427257f97ba7wq",
            path: "example.json",
            file_type: ".json",
            size: 46007,
            created_at: "2024-10-22T09:44:48.882335778Z",
          },
        ],
      });
    });
  }
  const formdata = new FormData();
  files.map((f) => formdata.append(f.file.name, f.file));
  return fetch(uploadFilesUrl(workspaceId, deviceId), {
    method: methodPost,
    body: formdata,
    headers: {
      Accept: "application/json",
      "X-API-KEY": apiKey,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const commit = (deviceId: string, schedule: boolean) => {
  if (isDev) {
    return new Promise((resolve, reject) => {
      resolve({
        version: "zfv-example",
        num: 3,
        size: 49127,
        files: [
          {
            id: "zfs-example",
            crc: "c9fd157f6fb037cb67427257f97ba7ea",
            path: "net.json",
            file_type: ".json",
            size: 46007,
            created_at: "2024-10-22T09:44:48.882336Z",
          },
          {
            id: "zfs-example",
            crc: "c782bdf70bf10c0a25651c3e5c278010",
            path: "params.json",
            file_type: ".json",
            size: 1560,
            created_at: "2024-05-21T09:14:59.111455Z",
          },
          {
            id: "zfs-example",
            crc: "c782bdf70bf10c0a25651c3e5c278010",
            path: "config.json",
            file_type: ".json",
            size: 1560,
            created_at: "2024-05-21T09:16:28.755145Z",
          },
        ],
      });
    });
  }
  return ZDMFetch(methodPost, commitUrl(workspaceId, deviceId, schedule));
};

export const getFirmwareVersions = () => {
  if (isDev) {
    return new Promise((resolve, reject) => {
      resolve({
        versions: [
          {
            firmware_id: "generic-4zb",
            version: "v0.0.0",
            metadata: {
              type: "graph",
              nodes: [
                {
                  pga: 2,
                  sps: 400,
                  name: "pow_sens",
                  type: "PowerSensor",
                  chtype: "current_ac",
                  channel: 1,
                  samples: 80,
                  conversion: {
                    type: "PowerConversion",
                    params: [2.048, 2048, 20, 3000, 4, 220, 0],
                  },
                },
                {
                  name: "pow",
                  type: "Aggregator",
                  input: [["pow_sens", 0]],
                },
                {
                  name: "erg",
                  type: "Aggregator",
                  input: [["pow_sens", 1]],
                },
                {
                  name: "pow_prod",
                  type: "HiThreshold",
                  input: [["pow_sens", 0]],
                  debounce: 0,
                  threshold: 40,
                },
                {
                  name: "pcs_ok_sens",
                  pull: "down",
                  type: "LevelSensor",
                  channel: 53,
                  inverted: true,
                },
                {
                  name: "pcs_ok_in",
                  type: "Pulse",
                  input: [["pcs_ok_sens", 0]],
                  duration: 0.5,
                },
                {
                  x: [["pcs_ok_in", 0]],
                  y: [["pow_prod", 0]],
                  op: "or",
                  name: "pcs_ok",
                  type: "BinPredicate",
                },
                {
                  name: "pcs_ok_cnt",
                  type: "Aggregator",
                  input: [["pcs_ok", 0]],
                },
                {
                  pga: 2,
                  sps: 100,
                  name: "pcs_ko_in",
                  type: "ResistiveSensor",
                  chtype: "resistive",
                  channel: 1,
                  samples: 10,
                  conversion: {
                    type: "NoConversion",
                  },
                },
                {
                  name: "pcs_ko_trig",
                  type: "LoThreshold",
                  input: [["pcs_ko_in", 0]],
                  debounce: 1,
                  threshold: 0.0,
                },
                {
                  name: "pcs_ko_pulse",
                  type: "Pulse",
                  input: [["pcs_ko_trig", 0]],
                  duration: 0.5,
                },
                {
                  name: "pcs_ko_cnt",
                  type: "Aggregator",
                  input: [["pcs_ko_pulse", 0]],
                },
                {
                  name: "alarm_in",
                  pull: "down",
                  type: "LevelSensor",
                  channel: 48,
                  inverted: true,
                },
                {
                  x: [["alarm_in", 0]],
                  y: 0,
                  op: "\u003e",
                  name: "alarm",
                  type: "BinPredicate",
                },
                {
                  name: "status",
                  type: "Status",
                  input: [
                    ["alarm", 0],
                    ["pow_prod", 0],
                  ],
                  labels: [0, 1, 2],
                  event_on: [0],
                },
                {
                  name: "status_times",
                  type: "Chronometer",
                  input: [["status", 0]],
                },
              ],
              events: [
                {
                  idx: 0,
                  key: "S",
                  tag: "s",
                  node: "status",
                },
              ],
              output: [
                {
                  key: "P",
                  tag: "d",
                  node: [
                    ["pcs_ok_cnt", 1],
                    ["pcs_ko_cnt", 1],
                  ],
                },
                {
                  key: "S",
                  tag: "d",
                  node: [["status", 0]],
                },
                {
                  key: "T",
                  tag: "d",
                  node: [["status_times", -1]],
                },
                {
                  key: "c_stats",
                  tag: "d",
                  node: [["pow", [0, 3, 4]]],
                },
                {
                  key: "C",
                  tag: "d",
                  node: [["erg", 1]],
                },
              ],
            },
            sources: [
              {
                src_id: "src-71hgupceat6h",
                name: "bootloader",
                file_name: "bootloader.bin",
                content_type: "application/octet-stream",
                file_path:
                  "/nfs/firmwares/public/generic-4zb/v0.0.0/src-71hgupceat6h",
                size: 23536,
                crc: "4eacb2db60a84148987c134845660d54",
              },
              {
                src_id: "src-71hgupclsi7u",
                name: "firmware",
                file_name: "firmware.bin",
                content_type: "application/octet-stream",
                file_path:
                  "/nfs/firmwares/public/generic-4zb/v0.0.0/src-71hgupclsi7u",
                size: 335860,
                crc: "1a04e0e00f3a5d40241a17be1902300a",
              },
              {
                src_id: "src-71hgupd5rozf",
                name: "otalog",
                file_name: "otalog.bin",
                content_type: "application/octet-stream",
                file_path:
                  "/nfs/firmwares/public/generic-4zb/v0.0.0/src-71hgupd5rozf",
                size: 65536,
                crc: "fcd6bcb56c1689fcef28b57c22475bad",
              },
              {
                src_id: "src-71hgupdd9e0s",
                name: "partitions",
                file_name: "partitions.bin",
                content_type: "application/octet-stream",
                file_path:
                  "/nfs/firmwares/public/generic-4zb/v0.0.0/src-71hgupdd9e0s",
                size: 3072,
                crc: "fc59f758b4968119b30506cfebc8bb1d",
              },
              {
                src_id: "src-71hgupdfrad9",
                name: "zerynth",
                file_name: "zerynth.bin",
                content_type: "application/octet-stream",
                file_path:
                  "/nfs/firmwares/public/generic-4zb/v0.0.0/src-71hgupdfrad9",
                size: 1791472,
                crc: "2b59523b79fbae1edb011267c88c47f5",
              },
            ],
            created_at: "2022-06-23T10:21:23.004853Z",
          },
        ],
        error: null,
      });
    });
  }
  return ZDMFetch(methodGet, getFirmwareVersionsUrl(workspaceId, firmwareId));
};

export const getCloudDevice = (deviceId: string) => {
  return ZDMFetch(methodGet, getCloudDeviceUrl(workspaceId, deviceId));
};

export const getZfs = (deviceId: string) => {
  return ZDMFetch(methodGet, getZfsUrl(workspaceId, deviceId));
};

export const getZfsFile = (deviceId: string, zfsId: string) => {
  return ZDMFetch(methodGet, getZfsFileUrl(workspaceId, deviceId, zfsId));
};

export const sendAlarmToRoasting = (
  email: string,
  installationId: string,
  installationName: string
) => {
  return verticalFetch(
    methodPost,
    sendAlarmToRoastingUrl(email),
    JSON.stringify({
      installation_id: installationId,
      installation_name: installationName,
    })
  );
};

export const checkZfsStatus = (deviceId: string) => {
  return ZDMFetch(methodGet, checkZfsStatusUrl(deviceId));
};
